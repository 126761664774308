<!-- 카테고리 -->
<template>
  <div>
    <v-app-bar
      class="fixed-bar-exhibition-event-header fixed-content-area top-transition"
      :style="`top : ${$flipBannerHeight}px;`"
      color="default"
      internal-activator
      bottom
      flat
      height="44"
    >
      <div class="d-flex justify-space-between fill-width align-center">
        <v-icon small @click="onClickPrevIcon">
          $vuetify.icons.prevIcon
        </v-icon>
        <span
          class="oneLine rixgo-extrabold align-center gray900--text font-g16-24"
          >{{ title }}</span
        >
        <div class="pb-1" @click="onClickShareIcon">
          <v-icon>$vuetify.icons.shareIcon</v-icon>
        </div>
      </div>
    </v-app-bar>
    <!-- First Image  -->
    <!-- TODO -->
    <div
      class="fill-width top-transition"
      :style="`z-index: 104; margin-top:${$flipBannerHeight + 44}px`"
    >
      <v-img eager :src="targetImageList.mainImage01" height="auto" />
      <!-- Second Image -->
      <v-img eager :src="targetImageList.mainImage02" height="auto" />
      <div v-if="seperateShopId === '326_3'">
        <v-img eager :src="targetImageList.mainImage03" height="auto" />
      </div>
      <div
        v-if="seperateShopId === '317'"
        class="pa-7"
        style="background-color: #ffe6e0"
      >
        <video
          controls="true"
          autoplay
          muted
          loop
          playsinline
          onloadstart="this.volume=0.2"
          class="fill-width"
          height="auto"
          type="video/mp4"
        >
          <source
            src="https://xexymix.jpg3.kr/xexymix/2020/sub/cate/swim_australia_mv.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div v-if="seperateShopId === '317'">
        <v-img :src="targetImageList.mainImage03" eager height="auto">
          <div>
            <v-img
              eager
              :src="targetImageList.moveToShopBtn"
              height="auto"
              style="transform: scale(0.6); margin-top: 124%"
              @click="moveToStore()"
            />
          </div>
        </v-img>
      </div>
      <div
        v-if="seperateShopId === '343'"
        class="pt-10 pb-20 pl-35 pr-35"
        style="background-color: #f6f6f6"
      >
        <CellookButton
          :buttonTitle="`브랜드샵 바로가기`"
          :background-color="`#D16C73`"
          :buttonTitleTextClass="`font-g14-20 rixgo-medium`"
          :text-color="`gray50`"
          borderRadius="0"
          @click="moveToStore()"
        />
      </div>
      <div
        v-if="seperateShopId === '431'"
        class="pt-5 pb-25 pl-35 pr-35"
        style="background-color: #e8e2cd"
      >
        <CellookButton
          :buttonTitle="`브랜드샵 바로가기`"
          :background-color="`#383838`"
          :buttonTitleTextClass="`font-g14-20 rixgo-semibold`"
          :text-color="`white`"
          borderRadius="100px"
          @click="moveToStore()"
        />
      </div>
      <div
        v-if="seperateShopId === '326'"
        class="pt-10 pb-20 pl-35 pr-35"
        style="background-color: #fcfcfc"
      >
        <CellookButton
          :buttonTitle="`브랜드샵 바로가기`"
          :background-color="`#383838`"
          :buttonTitleTextClass="`font-g14-20 rixgo-medium`"
          :text-color="`white`"
          borderRadius="100px"
          @click="moveToStore()"
        />
      </div>
      <div
        v-if="seperateShopId === '381'"
        class="pt-15 pb-15 pl-35 pr-35"
        style="background-color: #f9edde"
      >
        <CellookButton
          :buttonTitle="`브랜드샵 바로가기`"
          :background-color="`#1C1C1C`"
          :buttonTitleTextClass="`font-g14-20 rixgo-medium`"
          :text-color="`white`"
          borderRadius="100px"
          @click="moveToStore()"
        />
      </div>
      <div
        v-if="seperateShopId === '379'"
        class="pt-15 pb-15 pl-35 pr-35"
        style="background-color: black"
      >
        <CellookButton
          :buttonTitle="`브랜드샵 바로가기`"
          :background-color="`black`"
          :buttonTitleTextClass="`font-g14-20 rixgo-medium`"
          :text-color="`white`"
          borderRadius="0px"
          borderOutLine="1px solid white"
          @click="moveToStore()"
        />
      </div>
      <div
        v-if="seperateShopId === '495'"
        class="pt-15 pb-15 pl-35 pr-35"
        style="background-color: #f0ead9"
        borderRadius="100px"
      >
        <CellookButton
          :buttonTitle="`브랜드샵 바로가기`"
          :background-color="`#5F554B`"
          :buttonTitleTextClass="`font-g14-20 rixgo-medium`"
          :text-color="`white`"
          borderRadius="100px"
          @click="moveToStore()"
        />
      </div>
      <div
        v-if="seperateShopId === '324'"
        class="pt-15 pb-15 pl-35 pr-35"
        style="background-color: #f8f0e7"
        borderRadius="100px"
      >
        <CellookButton
          :buttonTitle="`브랜드샵 바로가기`"
          :background-color="`white`"
          :buttonTitleTextClass="`font-g14-20 rixgo-medium`"
          :text-color="`black`"
          borderRadius="0px"
          borderOutLine="1px solid black"
          @click="moveToStore()"
        />
      </div>
      <div
        v-if="seperateShopId === '326_2'"
        style="background-color: #8a8572"
        borderRadius="100px"
      >
        <div class="pt-2 pl-25 pr-25 mb-1">
          <CellookButton
            :buttonTitle="`브랜드샵 바로가기`"
            :background-color="`black`"
            :buttonTitleTextClass="`font-g14-20 rixgo-medium`"
            :text-color="`white`"
            borderRadius="100px"
            @click="moveToStore()"
          />
        </div>
        <v-img eager :src="targetImageList.mainImage08" height="auto" />
      </div>
      <div
        v-if="seperateShopId === '333'"
        class="pt-15 pb-15 pl-35 pr-35"
        style="background-color: #f67152"
        borderRadius="100px"
      >
        <CellookButton
          :buttonTitle="`브랜드샵 바로가기`"
          :background-color="`#F7DD4C`"
          :buttonTitleTextClass="`font-g14-20 rixgo-medium`"
          :text-color="`orange100`"
          borderRadius="100px"
          @click="moveToStore()"
        />
      </div>
      <div
        v-if="seperateShopId === '326_3'"
        class="pt-15 pb-15 pl-35 pr-35"
        style="background-color: #eaeae3"
        borderRadius="100px"
      >
        <CellookButton
          :buttonTitle="`브랜드샵 바로가기`"
          :background-color="`#465459`"
          :buttonTitleTextClass="`font-g14-20 rixgo-medium`"
          :text-color="`white`"
          @click="moveToStore()"
        />
      </div>
      <div
        v-if="seperateShopId === '379_2'"
        class="pt-15 pb-15 pl-35 pr-35"
        style="background-color: #7c5841"
        borderRadius="100px"
      >
        <CellookButton
          :buttonTitle="`브랜드샵 바로가기`"
          :background-color="`rgba(255, 255, 255, 0.1)`"
          :buttonTitleTextClass="`font-g14-20 rixgo-medium`"
          :text-color="`white`"
          borderOutLine="0.5px solid white"
          @click="moveToStore()"
        />
      </div>
      <div v-if="seperateShopId === '343'" class="pb-3">
        <v-img eager :src="targetImageList.mainImage03" height="auto" />
      </div>
      <div v-if="seperateShopId === '326'">
        <v-img
          eager
          :src="targetImageList.mainImage03"
          height="auto"
          class="mb-15"
        />
        <v-img eager :src="targetImageList.mainImage04" height="auto" />
      </div>
      <div v-if="seperateShopId === '431'" class="">
        <v-img eager :src="targetImageList.mainImage03" height="auto" />
        <v-img
          eager
          :src="targetImageList.mainImage04"
          height="auto"
          class="mb-10"
        />
        <v-img eager :src="targetImageList.mainImage05" height="auto" />
      </div>
      <div v-if="seperateShopId === '381'" class="">
        <v-img eager :src="targetImageList.mainImage03" height="auto" />
        <v-img
          eager
          :src="targetImageList.mainImage04"
          height="auto"
          class="mb-10"
        />
        <v-img eager :src="targetImageList.mainImage05" height="auto" />
      </div>
      <div v-if="seperateShopId === '379'" class="pb-3">
        <v-img eager :src="targetImageList.mainImage03" height="auto" />
        <div class="pt-10"></div>
        <v-img eager :src="targetImageList.mainImage04" height="auto" />
      </div>
      <div v-if="seperateShopId === '495'" class="pb-3">
        <v-img eager :src="targetImageList.mainImage03" height="auto" />
        <div class="pt-12">
          <v-img eager :src="targetImageList.mainImage04" height="auto" />
        </div>
      </div>
      <div v-if="seperateShopId === '324'" class="pb-3">
        <v-img eager :src="targetImageList.mainImage03" height="auto" />
        <v-img eager :src="targetImageList.mainImage04" height="auto" />
      </div>
      <div v-if="seperateShopId === '326_2'" class="">
        <v-img eager :src="targetImageList.mainImage03" height="auto" />
        <v-img eager :src="targetImageList.mainImage04" height="auto" />
        <div class="pt-15">
          <v-img eager :src="targetImageList.mainImage05" height="auto" />
        </div>
      </div>
      <div v-if="seperateShopId === '317'" class="pb-3">
        <v-img eager :src="targetImageList.mainImage04" height="auto" />
        <v-img eager :src="targetImageList.mainImage05" height="auto" />
      </div>
      <div v-if="seperateShopId === '333'">
        <div class="pb-15">
          <v-img eager :src="targetImageList.mainImage03" height="auto" />
        </div>
        <v-img eager :src="targetImageList.mainImage04" height="auto" />
      </div>
      <div v-if="seperateShopId === '326_3'">
        <v-img eager :src="targetImageList.mainImage04" height="auto" />
        <v-img eager :src="targetImageList.mainImage05" height="auto" />
      </div>
      <div v-if="seperateShopId === '379_2'">
        <div class="pb-7">
          <v-img eager :src="targetImageList.mainImage03" height="auto" />
        </div>
        <v-img eager :src="targetImageList.mainImage04" height="auto" />
      </div>
    </div>
    <div v-if="datafetching">
      <div
        v-if="exhibitionEventDataList.carouselList.length > 0"
        class="fill-width pb-16"
      >
        <v-carousel hide-delimiters :show-arrows="false" height="auto">
          <VueSlickCarousel
            ref="carousel"
            v-bind="carouselOption"
            :arrows="false"
            @afterChange="handleActive"
          >
            <div
              v-for="(itemList, index) in exhibitionEventDataList.carouselList"
              :key="itemList.productNo"
              class="pt-10 px-1"
            >
              <ProductCardImage
                eventMallType="single"
                rounded="rounded"
                :imageIndex="index"
                :targetImage="targetImage"
                :product="itemList"
                :aspectRatio="1"
                :gtagName="`collabo_rolling_${$numbering(index + 1)}`"
                @onClickBlurImage="onClickBlurImage"
              />
            </div>
          </VueSlickCarousel>
          <div class="d-flex align-center pt-8 pr-10 pl-10">
            <div class="font-g12-18 campton-bold pr-4">
              {{ active + 1 >= 10 ? active + 1 : '0' + (active + 1) }}
            </div>
            <v-progress-linear
              height="3"
              background-color="gray200"
              color="gray900"
              :value="
                ((active + 1) /
                  Math.ceil(exhibitionEventDataList.carouselList.length)) *
                100
              "
            ></v-progress-linear>
            <div class="font-g12-18 campton-bold pl-4">
              {{
                exhibitionEventDataList.carouselList.length >= 10
                  ? exhibitionEventDataList.carouselList.length
                  : '0' + exhibitionEventDataList.carouselList.length
              }}
            </div>
          </div>
        </v-carousel>
      </div>
      <div
        v-for="(itemList, index) in exhibitionEventDataList.productCardXList"
        :key="itemList.productNo"
        class="pl-10 pr-10"
      >
        <ProductCardX
          :product="itemList"
          :aspectRatio="1"
          :gtagName="`collabo_sec01_${$numbering(index + 1)}`"
        />
        <div
          :class="`${
            index === exhibitionEventDataList.productCardXList.length - 1
              ? 'mb-30'
              : 'bar mt-8 mb-8'
          }`"
        />
      </div>
      <div class="pb-5">
        <div v-if="seperateShopId === '343'">
          <v-img eager :src="targetImageList.mainImage04" height="auto" />
        </div>
        <div v-if="seperateShopId === '326'">
          <v-img eager :src="targetImageList.mainImage05" height="auto" />
        </div>
        <div v-if="seperateShopId === '431'">
          <v-img eager :src="targetImageList.mainImage06" height="auto" />
        </div>
        <div v-if="seperateShopId === '381'">
          <v-img eager :src="targetImageList.mainImage06" height="auto" />
        </div>
        <div v-if="seperateShopId === '379'">
          <v-img eager :src="targetImageList.mainImage05" height="auto" />
        </div>
        <div v-if="seperateShopId === '495'">
          <v-img eager :src="targetImageList.mainImage05" height="auto" />
        </div>
        <div v-if="seperateShopId === '324'">
          <v-img eager :src="targetImageList.mainImage05" height="auto" />
        </div>
        <div v-if="seperateShopId === '326_2'">
          <v-img eager :src="targetImageList.mainImage06" height="auto" />
        </div>
        <div v-if="seperateShopId === '317'">
          <v-img eager :src="targetImageList.mainImage06" height="auto" />
        </div>
        <div v-if="seperateShopId === '333'">
          <v-img eager :src="targetImageList.mainImage05" height="auto" />
        </div>
        <div v-if="seperateShopId === '326_3'">
          <v-img eager :src="targetImageList.mainImage06" height="auto" />
        </div>
        <div v-if="seperateShopId === '379_2'">
          <v-img eager :src="targetImageList.mainImage05" height="auto" />
        </div>
      </div>
      <div
        v-for="(
          itemList, index
        ) in exhibitionEventDataList.productCardXReversList"
        :key="itemList.productNo"
        :class="`${index === 0 ? 'pt-10' : ''} pl-10 pr-10`"
      >
        <ProductCardX
          :imageReverse="true"
          :product="itemList"
          :aspectRatio="1"
          :gtagName="`collabo_sec02_${$numbering(index + 1)}`"
        />
        <div
          :class="`${
            index === exhibitionEventDataList.productCardXReversList.length - 1
              ? 'mb-30'
              : 'bar mt-8 mb-8'
          }`"
        />
      </div>
      <div v-if="seperateShopId === '343'">
        <v-img eager :src="targetImageList.mainImage05" height="auto" />
      </div>
      <div v-if="seperateShopId === '326'">
        <v-img eager :src="targetImageList.mainImage06" height="auto" />
      </div>
      <div v-if="seperateShopId === '431'">
        <v-img eager :src="targetImageList.mainImage07" height="auto" />
      </div>
      <div v-if="seperateShopId === '381'">
        <v-img eager :src="targetImageList.mainImage07" height="auto" />
      </div>
      <div v-if="seperateShopId === '379'">
        <v-img eager :src="targetImageList.mainImage06" height="auto" />
      </div>
      <div v-if="seperateShopId === '495'">
        <v-img eager :src="targetImageList.mainImage06" height="auto" />
      </div>
      <div v-if="seperateShopId === '324'">
        <v-img eager :src="targetImageList.mainImage06" height="auto" />
      </div>
      <div v-if="seperateShopId === '326_2'">
        <v-img eager :src="targetImageList.mainImage07" height="auto" />
      </div>
      <div v-if="seperateShopId === '317'">
        <v-img eager :src="targetImageList.mainImage07" height="auto" />
      </div>
      <div v-if="seperateShopId === '333'">
        <v-img eager :src="targetImageList.mainImage06" height="auto" />
      </div>
      <div v-if="seperateShopId === '326_3'">
        <v-img eager :src="targetImageList.mainImage07" height="auto" />
      </div>
      <div v-if="seperateShopId === '379_2'">
        <v-img eager :src="targetImageList.mainImage06" height="auto" />
      </div>
      <div
        v-for="item in exhibitionEventDataList.bestItemList"
        :key="item.index"
        class="font-g16-25 rixgo-bold"
      >
        <div v-if="item.useYN">
          <div class="pa-10">
            {{ item.title }}
          </div>
          <div class="d-flex align-content-center flex-wrap pl-10 pr-10">
            <div
              v-for="(itemList, subListIndex) in item.searchProductList"
              :key="itemList.productNo"
              class="width-50 px-1 pb-10"
            >
              <ProductCardY
                :product="itemList"
                :gtagName="`collabo_cate${$numbering(
                  item.index + 1
                )}_${$numbering(subListIndex + 1)}`"
              />
            </div>
          </div>
          <div class="border-gray"></div>
        </div>
      </div>
    </div>
    <div class="font-g16-24 pa-10 rixgo-bold">다른 기획전</div>
    <div class="pl-10 pr-10">
      <div
        v-for="(item, index) in targetImageList.bannerImageList"
        :key="index"
      >
        <v-img
          eager
          :src="item.otherExhibitionImage"
          class="mb-8"
          @click="goExhibitionList(item.exhibitionNo, index)"
        />
      </div>
    </div>
    <div class="pt-20 pb-30 pl-10 pr-10">
      <CellookButton
        :buttonTitle="`MD's Pick 보기`"
        :background-color="`#003399`"
        :buttonTitleTextClass="`font-g14-20 rixgo-bold`"
        :text-color="`white`"
        @click="goMainMDSPick()"
      />
    </div>
    <Sharing
      :content="shareProps.description"
      :shareItem="shareItem"
      :show="share"
      :url="shareProps.copyUrl"
      :store="shareProps.title"
      sheetTitle="기획전"
      @onClose="share = false"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import Sharing from './../../common/dialogs/Sharing.vue'
import CellookButton from '@/components/common/buttons/CellookButton.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'
import ProductCardImage from '../../common/widgets/ProductCardImage.vue'
import ProductCardX from '../../common/widgets/ProductCardX.vue'
import ProductCardY from '../../common/widgets/ProductCardY.vue'
import { SearchProductUseCache } from '../../../api/displayAPI/ProductAPI'
import ShopNo343EventJson from '../../../assets/exhibitionEvent/ShopNo343Event.json'
import ShopNo326EventJson from '../../../assets/exhibitionEvent/ShopNo326Event.json'
import ShopNo431EventJson from '../../../assets/exhibitionEvent/ShopNo431Event.json'
import ShopNo381EventJson from '../../../assets/exhibitionEvent/ShopNo381Event.json'
import ShopNo379EventJson from '../../../assets/exhibitionEvent/ShopNo379Event.json'
import ShopNo495EventJson from '../../../assets/exhibitionEvent/ShopNo495Event.json'
import ShopNo324EventJson from '../../../assets/exhibitionEvent/ShopNo324Event.json'
import ShopNo326_2EventJson from '../../../assets/exhibitionEvent/ShopNo326_2Event.json'
import ShopNo317EventJson from '../../../assets/exhibitionEvent/ShopNo317Event.json'
import ShopNo333EventJson from '../../../assets/exhibitionEvent/ShopNo333Event.json'
import ShopNo326_3EventJson from '../../../assets/exhibitionEvent/ShopNo326_3Event.json'
import ShopNo379_2EventJson from '../../../assets/exhibitionEvent/ShopNo379_2Event.json'
export default Vue.extend({
  name: 'ExhibitionEventView',
  components: {
    CellookButton,
    ProductCardImage,
    ProductCardX,
    ProductCardY,
    VueSlickCarousel,
    Sharing,
  },
  props: {
    scrollLocation: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      targetImageList: {},
      imageList343: {
        mainImage01: require('../../../assets/images/event/shopId343/image_1.webp'),
        mainImage02: require('../../../assets/images/event/shopId343/image_2.webp'),
        mainImage03: require('../../../assets/images/event/shopId343/image_3.webp'),
        mainImage04: require('../../../assets/images/event/shopId343/image_4.webp'),
        mainImage05: require('../../../assets/images/event/shopId343/image_5.webp'),
        bannerImageList: [
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition343_01.png'),
            exhibitionNo: 44,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition343_02.png'),
            exhibitionNo: 40,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition343_03.png'),
            exhibitionNo: 39,
          },
        ],
      },
      imageList326: {
        mainImage01: require('../../../assets/images/event/shopId326/image_1.webp'),
        mainImage02: require('../../../assets/images/event/shopId326/image_2.webp'),
        mainImage03: require('../../../assets/images/event/shopId326/image_3.webp'),
        mainImage04: require('../../../assets/images/event/shopId326/image_4.webp'),
        mainImage05: require('../../../assets/images/event/shopId326/image_5.webp'),
        mainImage06: require('../../../assets/images/event/shopId326/image_6.webp'),
        bannerImageList: [
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition343_01.png'),
            exhibitionNo: 44,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition343_02.png'),
            exhibitionNo: 40,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition343_03.png'),
            exhibitionNo: 39,
          },
        ],
      },
      imageList431: {
        mainImage01: require('../../../assets/images/event/shopId431/image_1.webp'),
        mainImage02: require('../../../assets/images/event/shopId431/image_2.webp'),
        mainImage03: require('../../../assets/images/event/shopId431/image_3.webp'),
        mainImage04: require('../../../assets/images/event/shopId431/image_4.webp'),
        mainImage05: require('../../../assets/images/event/shopId431/image_5.webp'),
        mainImage06: require('../../../assets/images/event/shopId431/image_6.webp'),
        mainImage07: require('../../../assets/images/event/shopId431/image_7.webp'),
        bannerImageList: [
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition381_01.png'),
            exhibitionNo: 48,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition381_02.png'),
            exhibitionNo: 53,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition381_03.png'),
            exhibitionNo: 52,
          },
        ],
      },
      imageList381: {
        mainImage01: require('../../../assets/images/event/shopId381/image_1.webp'),
        mainImage02: require('../../../assets/images/event/shopId381/image_2.webp'),
        mainImage03: require('../../../assets/images/event/shopId381/image_3.webp'),
        mainImage04: require('../../../assets/images/event/shopId381/image_4.webp'),
        mainImage05: require('../../../assets/images/event/shopId381/image_5.webp'),
        mainImage06: require('../../../assets/images/event/shopId381/image_6.webp'),
        mainImage07: require('../../../assets/images/event/shopId381/image_7.webp'),
        bannerImageList: [
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition381_01.png'),
            exhibitionNo: 48,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition381_02.png'),
            exhibitionNo: 53,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition381_03.png'),
            exhibitionNo: 52,
          },
        ],
      },
      imageList379: {
        mainImage01: require('../../../assets/images/event/shopId379/image_1.webp'),
        mainImage02: require('../../../assets/images/event/shopId379/image_2.webp'),
        mainImage03: require('../../../assets/images/event/shopId379/image_3.webp'),
        mainImage04: require('../../../assets/images/event/shopId379/image_4.webp'),
        mainImage05: require('../../../assets/images/event/shopId379/image_5.webp'),
        mainImage06: require('../../../assets/images/event/shopId379/image_6.webp'),
        bannerImageList: [
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition379_01.png'),
            exhibitionNo: 62,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition379_02.png'),
            exhibitionNo: 61,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition379_03.png'),
            exhibitionNo: 52,
          },
        ],
      },
      imageList495: {
        mainImage01: require('../../../assets/images/event/shopId495/image_1.webp'),
        mainImage02: require('../../../assets/images/event/shopId495/image_2.webp'),
        mainImage03: require('../../../assets/images/event/shopId495/image_3.webp'),
        mainImage04: require('../../../assets/images/event/shopId495/image_4.webp'),
        mainImage05: require('../../../assets/images/event/shopId495/image_5.webp'),
        mainImage06: require('../../../assets/images/event/shopId495/image_6.webp'),
        bannerImageList: [
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition379_01.png'),
            exhibitionNo: 62,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition379_02.png'),
            exhibitionNo: 61,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition379_03.png'),
            exhibitionNo: 52,
          },
        ],
      },
      imageList324: {
        mainImage01: require('../../../assets/images/event/shopId324/image_1.webp'),
        mainImage02: require('../../../assets/images/event/shopId324/image_2.webp'),
        mainImage03: require('../../../assets/images/event/shopId324/image_3.webp'),
        mainImage04: require('../../../assets/images/event/shopId324/image_4.webp'),
        mainImage05: require('../../../assets/images/event/shopId324/image_5.webp'),
        mainImage06: require('../../../assets/images/event/shopId324/image_6.webp'),
        bannerImageList: [
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition379_01.png'),
            exhibitionNo: 62,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition379_02.png'),
            exhibitionNo: 61,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition379_03.png'),
            exhibitionNo: 52,
          },
        ],
      },
      imageList326_2: {
        mainImage01: require('../../../assets/images/event/shopId326_2/image_1.webp'),
        mainImage02: require('../../../assets/images/event/shopId326_2/image_2.webp'),
        mainImage03: require('../../../assets/images/event/shopId326_2/image_3.webp'),
        mainImage04: require('../../../assets/images/event/shopId326_2/image_4.webp'),
        mainImage05: require('../../../assets/images/event/shopId326_2/image_5.webp'),
        mainImage06: require('../../../assets/images/event/shopId326_2/image_6.webp'),
        mainImage07: require('../../../assets/images/event/shopId326_2/image_7.webp'),
        mainImage08: require('../../../assets/images/event/shopId326_2/image_8.webp'),
        bannerImageList: [
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition379_01.png'),
            exhibitionNo: 62,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition379_02.png'),
            exhibitionNo: 61,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/otherExhibition379_03.png'),
            exhibitionNo: 52,
          },
        ],
      },
      imageList317: {
        mainImage01: require('../../../assets/images/event/shopId317/image_1.webp'),
        mainImage02: require('../../../assets/images/event/shopId317/image_2.webp'),
        mainImage03: require('../../../assets/images/event/shopId317/image_3.webp'),
        mainImage04: require('../../../assets/images/event/shopId317/image_4.webp'),
        mainImage05: require('../../../assets/images/event/shopId317/image_5.webp'),
        mainImage06: require('../../../assets/images/event/shopId317/image_6.webp'),
        mainImage07: require('../../../assets/images/event/shopId317/image_7.webp'),
        moveToShopBtn: require('../../../assets/images/event/shopId317/moveToShopBtn.webp'),
        bannerImageList: [
          {
            otherExhibitionImage: require('../../../assets/images/event/shopId317/bottomBanner_1.webp'),
            exhibitionNo: 68,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/shopId317/bottomBanner_2.webp'),
            exhibitionNo: 66,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/shopId317/bottomBanner_3.webp'),
            exhibitionNo: 69,
          },
        ],
      },
      imageList333: {
        mainImage01: require('../../../assets/images/event/shopId333/image_1.webp'),
        mainImage02: require('../../../assets/images/event/shopId333/image_2.webp'),
        mainImage03: require('../../../assets/images/event/shopId333/image_3.webp'),
        mainImage04: require('../../../assets/images/event/shopId333/image_4.webp'),
        mainImage05: require('../../../assets/images/event/shopId333/image_5.webp'),
        mainImage06: require('../../../assets/images/event/shopId333/image_6.webp'),
        bannerImageList: [
          {
            otherExhibitionImage: require('../../../assets/images/event/shopId317/bottomBanner_1.webp'),
            exhibitionNo: 68,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/shopId317/bottomBanner_2.webp'),
            exhibitionNo: 66,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/shopId317/bottomBanner_3.webp'),
            exhibitionNo: 69,
          },
        ],
      },
      imageList326_3: {
        mainImage01: require('../../../assets/images/event/shopId326_3/image_1.webp'),
        mainImage02: require('../../../assets/images/event/shopId326_3/image_2.webp'),
        mainImage03: require('../../../assets/images/event/shopId326_3/image_3.webp'),
        mainImage04: require('../../../assets/images/event/shopId326_3/image_4.webp'),
        mainImage05: require('../../../assets/images/event/shopId326_3/image_5.webp'),
        mainImage06: require('../../../assets/images/event/shopId326_3/image_6.webp'),
        mainImage07: require('../../../assets/images/event/shopId326_3/image_7.webp'),
        bannerImageList: [
          {
            otherExhibitionImage: require('../../../assets/images/event/shopId317/bottomBanner_1.webp'),
            exhibitionNo: 68,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/shopId317/bottomBanner_2.webp'),
            exhibitionNo: 66,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/shopId317/bottomBanner_3.webp'),
            exhibitionNo: 69,
          },
        ],
      },
      imageList379_2: {
        mainImage01: require('../../../assets/images/event/shopId379_2/image_1.webp'),
        mainImage02: require('../../../assets/images/event/shopId379_2/image_2.webp'),
        mainImage03: require('../../../assets/images/event/shopId379_2/image_3.webp'),
        mainImage04: require('../../../assets/images/event/shopId379_2/image_4.webp'),
        mainImage05: require('../../../assets/images/event/shopId379_2/image_5.webp'),
        mainImage06: require('../../../assets/images/event/shopId379_2/image_6.webp'),
        bannerImageList: [
          {
            otherExhibitionImage: require('../../../assets/images/event/bottomBanner_0801_1.webp'),
            exhibitionNo: 75,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/bottomBanner_0801_2.webp'),
            exhibitionNo: 77,
          },
          {
            otherExhibitionImage: require('../../../assets/images/event/bottomBanner_0801_3.webp'),
            exhibitionNo: 74,
          },
        ],
      },

      share: false,
      title: '',
      shopId: '',
      seperateShopId: '',
      shareItem: {},
      shareProps: {},
      exhibitionEventDataList: {},
      requestProductNoList: {},
      GAData: {
        location: `${process.env.VUE_APP_CELLOOK_URL}/exhibitionEvent`,
        path: '/exhibitionEvent',
      },
      carouselOption: {
        dotsClass: 'slick-dots custom-dot-class',
        centerMode: true,
        centerPadding: '27%',
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
        swipeToSlide: true,
        focusOnSelect: true,
      },
      targetImage: 0,
      active: 0,
      datafetching: false,
    }
  },
  async created() {
    this.fetchLoading({ yn: true })
    this.$getAppHtml.addEventListener('scroll', this.handleScroll)
    //shopId에 맞게 JSON 데이터 바인딩
    this.shopId = this.$route.query.eventShopId ?? this.$route.query.shopId
    this.checkShopId(this.shopId)
    try {
      SearchProductUseCache(this.requestProductNoList.carouselList).then(
        (result) => {
          if (result.data) {
            this.exhibitionEventDataList.carouselList = result.data
          }
        }
      ),
        SearchProductUseCache(this.requestProductNoList.productCardXList).then(
          (result) => {
            if (result.data) {
              this.exhibitionEventDataList.productCardXList = result.data
            }
          }
        ),
        SearchProductUseCache(
          this.requestProductNoList.productCardXReversList
        ).then((result) => {
          if (result.data) {
            this.exhibitionEventDataList.productCardXReversList = result.data
          }
        }),
        SearchProductUseCache(
          this.requestProductNoList.bestItemList.bestItemList1
        ).then((result) => {
          if (result.data) {
            this.exhibitionEventDataList.bestItemList.bestItemList1.searchProductList =
              result.data
          }
        }),
        SearchProductUseCache(
          this.requestProductNoList.bestItemList.bestItemList2
        ).then((result) => {
          if (result.data) {
            this.exhibitionEventDataList.bestItemList.bestItemList2.searchProductList =
              result.data
          }
        }),
        SearchProductUseCache(
          this.requestProductNoList.bestItemList.bestItemList3
        ).then((result) => {
          if (result.data) {
            this.exhibitionEventDataList.bestItemList.bestItemList3.searchProductList =
              result.data
          }
        }),
        SearchProductUseCache(
          this.requestProductNoList.bestItemList.bestItemList4
        ).then((result) => {
          if (result.data) {
            this.exhibitionEventDataList.bestItemList.bestItemList4.searchProductList =
              result.data
          }
        })
      if (this.requestProductNoList.bestItemList.bestItemList5 !== undefined) {
        SearchProductUseCache(
          this.requestProductNoList.bestItemList.bestItemList5
        ).then((result) => {
          if (result.data) {
            this.exhibitionEventDataList.bestItemList.bestItemList5.searchProductList =
              result.data
          }
        })
      }
      if (this.requestProductNoList.bestItemList.bestItemList6 !== undefined) {
        SearchProductUseCache(
          this.requestProductNoList.bestItemList.bestItemList6
        ).then((result) => {
          if (result.data) {
            this.exhibitionEventDataList.bestItemList.bestItemList6.searchProductList =
              result.data
          }
        })
      }
    } catch (error) {
      console.log('error')
    } finally {
      this.fetchLoading({ yn: false })
      this.datafetching = true
    }
  },
  destroyed() {
    this.$getAppHtml.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.setScrollLocation()
  },
  computed: {
    ...mapState('BannerStore', ['onBanner']),
  },
  methods: {
    ...mapActions('HomeStore', ['fetchMainTab']),
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('ExhibitionStore', ['fetchEventExhibitionScrollLocation']),
    setScrollLocation() {
      setTimeout(() => {
        this.$getAppHtml.scrollTo({
          top: this.scrollLocation,
          behavior: 'auto',
        })
      }, 1000)
    },
    checkShopId(eventShopId) {
      // if (shopId !== '343' && shopId !== '326') {
      //   alert('잘못된 기획전 입니다')
      //   this.$router.push('/')
      // }
      switch (eventShopId) {
        case '343':
          this.targetImageList = this.imageList343
          this.title = ShopNo343EventJson.title
          this.shopId = ShopNo343EventJson.shopId
          this.seperateShopId = ShopNo343EventJson.seperateShopId
          this.shareItem = ShopNo343EventJson.shareItem
          this.shareItem.url = window.location.origin + '/?eventShopId=343'
          this.shareProps = ShopNo343EventJson.shareProps
          this.shareProps.copyUrl = window.location.origin + '/?eventShopId=343'
          this.exhibitionEventDataList =
            ShopNo343EventJson.exhibitionEventDataList
          this.requestProductNoList = ShopNo343EventJson.requestProductNoList
          break
        case '326':
          this.targetImageList = this.imageList326
          this.title = ShopNo326EventJson.title
          this.shopId = ShopNo326EventJson.shopId
          this.seperateShopId = ShopNo326EventJson.seperateShopId
          this.shareItem = ShopNo326EventJson.shareItem
          this.shareItem.url = window.location.origin + '/?eventShopId=326'
          this.shareProps = ShopNo326EventJson.shareProps
          this.shareProps.copyUrl = window.location.origin + '/?eventShopId=326'
          this.exhibitionEventDataList =
            ShopNo326EventJson.exhibitionEventDataList
          this.requestProductNoList = ShopNo326EventJson.requestProductNoList
          break
        case '431':
          this.targetImageList = this.imageList431
          this.title = ShopNo431EventJson.title
          this.shopId = ShopNo431EventJson.shopId
          this.seperateShopId = ShopNo431EventJson.seperateShopId
          this.shareItem = ShopNo431EventJson.shareItem
          this.shareItem.url = window.location.origin + '/?eventShopId=431'
          this.shareProps = ShopNo431EventJson.shareProps
          this.shareProps.copyUrl = window.location.origin + '/?eventShopId=431'
          this.exhibitionEventDataList =
            ShopNo431EventJson.exhibitionEventDataList
          this.requestProductNoList = ShopNo431EventJson.requestProductNoList
          break
        case '381':
          this.targetImageList = this.imageList381
          this.title = ShopNo381EventJson.title
          this.shopId = ShopNo381EventJson.shopId
          this.seperateShopId = ShopNo381EventJson.seperateShopId
          this.shareItem = ShopNo381EventJson.shareItem
          this.shareItem.url = window.location.origin + '/?eventShopId=381'
          this.shareProps = ShopNo381EventJson.shareProps
          this.shareProps.copyUrl = window.location.origin + '/?eventShopId=381'
          this.exhibitionEventDataList =
            ShopNo381EventJson.exhibitionEventDataList
          this.requestProductNoList = ShopNo381EventJson.requestProductNoList
          break
        case '379':
          this.targetImageList = this.imageList379
          this.title = ShopNo379EventJson.title
          this.shopId = ShopNo379EventJson.shopId
          this.seperateShopId = ShopNo379EventJson.seperateShopId
          this.shareItem = ShopNo379EventJson.shareItem
          this.shareItem.url = window.location.origin + '/?eventShopId=379'
          this.shareProps = ShopNo379EventJson.shareProps
          this.shareProps.copyUrl = window.location.origin + '/?eventShopId=379'
          this.exhibitionEventDataList =
            ShopNo379EventJson.exhibitionEventDataList
          this.requestProductNoList = ShopNo379EventJson.requestProductNoList
          break
        case '495':
          this.targetImageList = this.imageList495
          this.title = ShopNo495EventJson.title
          this.shopId = ShopNo495EventJson.shopId
          this.seperateShopId = ShopNo495EventJson.seperateShopId
          this.shareItem = ShopNo495EventJson.shareItem
          this.shareItem.url = window.location.origin + '/?eventShopId=495'
          this.shareProps = ShopNo495EventJson.shareProps
          this.shareProps.copyUrl = window.location.origin + '/?eventShopId=495'
          this.exhibitionEventDataList =
            ShopNo495EventJson.exhibitionEventDataList
          this.requestProductNoList = ShopNo495EventJson.requestProductNoList
          break
        case '324':
          this.targetImageList = this.imageList324
          this.title = ShopNo324EventJson.title
          this.shopId = ShopNo324EventJson.shopId
          this.seperateShopId = ShopNo324EventJson.seperateShopId
          this.shareItem = ShopNo324EventJson.shareItem
          this.shareItem.url = window.location.origin + '/?eventShopId=324'
          this.shareProps = ShopNo324EventJson.shareProps
          this.shareProps.copyUrl = window.location.origin + '/?eventShopId=324'
          this.exhibitionEventDataList =
            ShopNo324EventJson.exhibitionEventDataList
          this.requestProductNoList = ShopNo324EventJson.requestProductNoList
          break
        case '326_2':
          this.targetImageList = this.imageList326_2
          this.title = ShopNo326_2EventJson.title
          this.shopId = ShopNo326_2EventJson.shopId
          this.seperateShopId = ShopNo326_2EventJson.seperateShopId
          this.shareItem = ShopNo326_2EventJson.shareItem
          this.shareItem.url = window.location.origin + '/?eventShopId=326_2'
          this.shareProps = ShopNo326_2EventJson.shareProps
          this.shareProps.copyUrl =
            window.location.origin + '/?eventShopId=326_2'
          this.exhibitionEventDataList =
            ShopNo326_2EventJson.exhibitionEventDataList
          this.requestProductNoList = ShopNo326_2EventJson.requestProductNoList
          break
        case '317':
          this.targetImageList = this.imageList317
          this.title = ShopNo317EventJson.title
          this.shopId = ShopNo317EventJson.shopId
          this.seperateShopId = ShopNo317EventJson.seperateShopId
          this.shareItem = ShopNo317EventJson.shareItem
          this.shareItem.url = window.location.origin + '/?eventShopId=317'
          this.shareProps = ShopNo317EventJson.shareProps
          this.shareProps.copyUrl = window.location.origin + '/?eventShopId=317'
          this.exhibitionEventDataList =
            ShopNo317EventJson.exhibitionEventDataList
          this.requestProductNoList = ShopNo317EventJson.requestProductNoList
          break
        case '333':
          this.targetImageList = this.imageList333
          this.title = ShopNo333EventJson.title
          this.shopId = ShopNo333EventJson.shopId
          this.seperateShopId = ShopNo333EventJson.seperateShopId
          this.shareItem = ShopNo333EventJson.shareItem
          this.shareItem.url = window.location.origin + '/?eventShopId=333'
          this.shareProps = ShopNo333EventJson.shareProps
          this.shareProps.copyUrl = window.location.origin + '/?eventShopId=333'
          this.exhibitionEventDataList =
            ShopNo333EventJson.exhibitionEventDataList
          this.requestProductNoList = ShopNo333EventJson.requestProductNoList
          break
        case '326_3':
          this.targetImageList = this.imageList326_3
          this.title = ShopNo326_3EventJson.title
          this.shopId = ShopNo326_3EventJson.shopId
          this.seperateShopId = ShopNo326_3EventJson.seperateShopId
          this.shareItem = ShopNo326_3EventJson.shareItem
          this.shareItem.url = window.location.origin + '/?eventShopId=326_3'
          this.shareProps = ShopNo326_3EventJson.shareProps
          this.shareProps.copyUrl =
            window.location.origin + '/?eventShopId=326_3'
          this.exhibitionEventDataList =
            ShopNo326_3EventJson.exhibitionEventDataList
          this.requestProductNoList = ShopNo326_3EventJson.requestProductNoList
          this.GAData.title = '20220731_클릭앤퍼니'
          this.$trackingPromotion(this.GAData)
          break
        case '379_2':
          this.targetImageList = this.imageList379_2
          this.title = ShopNo379_2EventJson.title
          this.shopId = ShopNo379_2EventJson.shopId
          this.seperateShopId = ShopNo379_2EventJson.seperateShopId
          this.shareItem = ShopNo379_2EventJson.shareItem
          this.shareItem.url = window.location.origin + '/?eventShopId=379_2'
          this.shareProps = ShopNo379_2EventJson.shareProps
          this.shareProps.copyUrl =
            window.location.origin + '/?eventShopId=379_2'
          this.exhibitionEventDataList =
            ShopNo379_2EventJson.exhibitionEventDataList
          this.requestProductNoList = ShopNo379_2EventJson.requestProductNoList
          this.GAData.title = '20220808_매그제이2'
          this.$trackingPromotion(this.GAData)
          break
      }
    },
    onClickPrevIcon() {
      this.$tracking('collabo_back')
      this.$router.push('/')
    },
    onClickShareIcon() {
      this.$tracking('collabo_share')
      this.share = true
    },
    handleActive(active) {
      this.active = active
      this.targetImage = active
    },
    onClickBlurImage(imageIndex) {
      this.$tracking(`collabo_rolling_${this.$numbering(imageIndex)}`)
      this.$refs.carousel.goTo(imageIndex)
    },
    moveToStore() {
      this.$tracking('collabo_brandshop')
      this.setExhibitionEventScrollLocation()
      this.$router.push({ name: 'StoreHome', query: { shopId: this.shopId } })
    },
    goMainMDSPick() {
      this.$tracking('collabo_mdpick')
      this.fetchMainTab({ tab: 3 })
      this.$router.push('/')
    },
    handleScroll() {
      this.fetchEventExhibitionScrollLocation(this.$getAppHtml.scrollTop)
    },
    goExhibitionList(promotionNo, index) {
      this.$tracking(`collabo_other_${this.$numbering(index + 1)}`)
      this.setExhibitionEventScrollLocation()
      this.$router.push({
        name: 'CategoryExhibitionList',
        query: {
          promotionNo: promotionNo,
          previousPage: 'exhibitionEvent?shopId=' + this.seperateShopId,
        },
      })
    },
    setExhibitionEventScrollLocation() {
      localStorage.setItem(
        'exhibitionEventScrollLocation',
        JSON.stringify(this.$getAppHtml.scrollTop)
      )
    },
  },
})
</script>
<style scoped>
.fixed-bar-page {
  margin-top: 6.1em;
}
.fixed-bar-exhibition-event-header {
  /* 2022.07.26 기준 이전 style */
  /* Flip Banner 추가로 인한 style 수정 */
  /* position: sticky;
  top: 0em;
  z-index: 20; */

  position: fixed;
  width: 100%;
  z-index: 20;
}

.bar {
  border-bottom: 1px solid #eaebed;
}
</style>
